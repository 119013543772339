import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title as string;
  }
  next(); //执行进入路由，如果不写就不会进入目标页
});

const app = createApp(App);

app.use(router);
app.mount('#app');
