import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/views/home/Home.vue'),
    meta: {
      title: '时空管理局',
    },
  },
  {
    path: '/login/verification',
    component: () => import('@/views/login/LoginVerificationCode.vue'),
    meta: {
      title: '时空管理局',
    },
  },
  {
    path: '/login/reset',
    component: () => import('@/views/reset/ResetCmd.vue'),
    meta: {
      title: '时空管理局',
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

export default router;
